import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { ProficloudInputConfig } from '@shared/type-definitions/types'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-add-charge-point-modal',
  templateUrl: './ocpp-proxy-add-charge-point-modal.component.html',
  styleUrls: ['./ocpp-proxy-add-charge-point-modal.component.scss'],
})
export class OcppProxyAddChargePointModalComponent implements OnInit {
  fields: ProficloudInputConfig[]

  addChargePointForm: UntypedFormGroup

  selects: any[] = []

  constructor(
    public ui: UiService,
    public proficloud: ProficloudService,
    public ocppProxy: OcppProxyService
  ) {}

  ngOnInit(): void {
    this.createFields()
    this.addChargePointForm = new UntypedFormGroup(this.ui.formFieldsToObject(this.fields))
  }

  getBackendProviderSelect(): any {
    this.selects = []
    this.ocppProxy.backend_providers.forEach((backend_provider) => {
      this.selects.push({
        key: backend_provider.backendProviderId,
        value: backend_provider.backendProviderName,
        visible: () => true,
      })
    })
  }

  createFields() {
    this.getBackendProviderSelect()
    this.fields = [
      {
        type: 'text',
        placeholder: 'Charge Point Name',
        helpText: 'e.g. Building 5.12',
        icon: 'label',
        control: new UntypedFormControl('', [Validators.maxLength(80), Validators.required]),
        key: 'chargepoint_name',
        inputId: 'add-charge-point-name',
      },
      {
        type: 'number',
        placeholder: 'Connector Count',
        helpText: 'e.g. 2',
        icon: 'label',
        control: new UntypedFormControl('', [Validators.required, Validators.min(0)]),
        key: 'chargepoint_connector_count',
        inputId: 'add-charge-point-connector-count',
      },
      {
        type: 'select',
        selectValues: this.selects,
        placeholder: 'Backend Provider',
        helpText: 'e.g. Steve OCPP Backend',
        control: new UntypedFormControl('', [Validators.required]),
        key: 'backend_provider_id',
        inputId: 'add-charge-point-backend-provider-id',
      },
      {
        type: 'text',
        placeholder: 'Backend Provider Username',
        helpText: 'e.g. 4e40b9d7-36ba-4abb-a106-567259f37903',
        icon: 'label',
        control: new UntypedFormControl('', [Validators.maxLength(80)]),
        key: 'backend_provider_username',
        inputId: 'add-charge-point-backend-provider-username',
      },
      {
        type: 'text',
        placeholder: 'Backend Provider Password',
        helpText: 'e.g. 4e40b9d7-36ba-4abb-a106-567259f37903',
        icon: 'label',
        control: new UntypedFormControl('', [Validators.maxLength(80)]),
        key: 'backend_provider_password',
        inputId: 'add-charge-point-backend-provider-password',
      },
    ]
  }

  clearForm() {
    this.fields.forEach((field) => (field.value = ''))
  }

  submit() {
    const nameField = this.fields.find((field) => field.key === 'chargepoint_name')
    const connectorField = this.fields.find((field) => field.key === 'chargepoint_connector_count')
    const backendProviderIdField = this.fields.find((field) => field.key === 'backend_provider_id')
    const backendProviderUserField = this.fields.find((field) => field.key === 'backend_provider_username')
    const backendProviderPasswordField = this.fields.find((field) => field.key === 'backend_provider_password')

    if (
      nameField &&
      nameField.control &&
      connectorField &&
      connectorField.control &&
      backendProviderIdField &&
      backendProviderIdField.control &&
      backendProviderUserField &&
      backendProviderUserField.control &&
      backendProviderPasswordField &&
      backendProviderPasswordField.control
    ) {
      const chargePointName = nameField.control.value.toString()
      const connectorCount = Number(connectorField.control.value)
      const backendProviderId = backendProviderIdField.control.value.toString()
      const backendProviderUsername = backendProviderUserField.control.value.toString()
      const backendProviderPassword = backendProviderPasswordField.control.value.toString()

      this.ocppProxy.addChargePoint(chargePointName, connectorCount, backendProviderId, backendProviderUsername, backendProviderPassword)

      this.clearForm()
      this.ocppProxy.showAddChargePointOverlay$.next(false)
    }
  }
}
