import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { UiService } from '@services/ui/ui.service'
import { ProficloudInputConfig } from '@shared/type-definitions/types'
import { BackendProvider } from '../../services/ocpp-proxy.interfaces'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-edit-backend-provider-modal',
  templateUrl: './ocpp-proxy-edit-backend-provider-modal.component.html',
  styleUrls: ['./ocpp-proxy-edit-backend-provider-modal.component.scss'],
})
export class OcppProxyEditBackendProviderModalComponent implements OnInit {
  fields: ProficloudInputConfig[]

  editBackendProviderForm: UntypedFormGroup

  @Input()
  backendProvider: BackendProvider

  constructor(
    public ocppProxy: OcppProxyService,
    public ui: UiService
  ) {}

  ngOnInit(): void {
    this.createFields()
    this.editBackendProviderForm = new UntypedFormGroup(this.ui.formFieldsToObject(this.fields))
  }

  createFields() {
    this.fields = [
      {
        type: 'text',
        placeholder: 'Backend Provider Name',
        helpText: 'e.g. Steve OCPP Backend',
        icon: 'label',
        value: this.backendProvider.backendProviderName,
        control: new UntypedFormControl(this.backendProvider.backendProviderName, [Validators.maxLength(80), Validators.required]),
        key: 'name',
        inputId: 'add-backend-provider-name',
      },
      {
        type: 'text',
        placeholder: 'Backend URL',
        helpText: 'e.g. wss://ocpp.my-backend/charge-points/',
        icon: 'label',
        value: this.backendProvider.backendProviderUrl,
        control: new UntypedFormControl(this.backendProvider.backendProviderUrl, [Validators.maxLength(80), Validators.required]),
        key: 'url',
        inputId: 'add-backend-provider-url',
      },
    ]
  }

  inputChanged(field: ProficloudInputConfig, $event: any) {}

  clearForm() {
    this.fields.forEach((field) => (field.value = ''))
  }

  submit() {
    if (this.fields) {
      let name = this.fields.find((field) => field.key === 'name')?.value as string
      let url = this.fields.find((field) => field.key === 'url')?.value as string

      this.ocppProxy.editBackendProvider(name, url, this.backendProvider.backendProviderId)

      this.clearForm()
      this.ocppProxy.showEditBackendProviderOverlay$.next(false)
    } else {
      console.error('No fields provided. Property is undefined.')
    }
  }
}
