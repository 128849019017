import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SessionData } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { PcStatusOverlayService } from '@shared/services/pc-status-overlay/pc-status-overlay.service'
import { PCStatusOverlayConfig } from '@shared/type-definitions/types'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Subject } from 'rxjs'
import {
  BackendProvider,
  BackendProviderResponse,
  ChargePoint,
  ChargePointBackendProvider,
  ChargePointLogMessage,
  ChargePointMeterValuesResponse,
  ChargePointPermissionsResponse,
  ChargePointResponse,
  ChargePointStatusResponse,
} from './ocpp-proxy.interfaces'

@Injectable({
  providedIn: 'root',
})
export class OcppProxyService {
  charge_points: ChargePoint[] = []

  backend_providers: BackendProvider[] = []

  ocppProxyEndpointMap: Record<string, string> = {
    localhost: 'wss://ocpp.proficloud-development.io/',
    'app.proficloud-dev.io': 'wss://ocpp.proficloud-development.io/',
    'app.proficloud-staging.io': 'wss://ocpp.proficloud-staging.io/',
    'app.proficloud.io': 'wss://ocpp.proficloud-production.io/',
  }

  ocppProxyEndpoint: string

  statusOverlays: Record<string, PCStatusOverlayConfig> = {
    addBackendProviderSuccess: {
      message: 'The Backend Provider has been added.',
      type: 'success',
      closeable: true,
    },
    addBackendProviderError: {
      message: 'The Backend Provider could not be added, please try again.',
      type: 'error',
      closeable: true,
    },
    editBackendProviderSuccess: {
      message: 'The Backend Provider has been edited',
      type: 'success',
      closeable: true,
    },
    editBackendProviderError: {
      message: 'The Backend Provider could not be edited',
      type: 'error',
      closeable: true,
    },
    deleteBackendProviderSuccess: {
      message: 'The Backend Provider has been deleted',
      type: 'success',
      closeable: true,
    },
    deleteBackendProviderError: {
      message: 'The Backend Provider could not be deleted',
      type: 'error',
      closeable: true,
    },
    addChargePointSuccess: {
      message: 'The Charge Point has been added.',
      type: 'success',
      closeable: true,
    },
    addChargePointError: {
      message: 'The Charge Point could not be added, please try again.',
      type: 'error',
      closeable: true,
    },
    editChargePointSuccess: {
      message: 'The Charge Point has been edited',
      type: 'success',
      closeable: true,
    },
    editChargePointError: {
      message: 'The Charge Point could not be edited',
      type: 'error',
      closeable: true,
    },
    deleteChargePointSuccess: {
      message: 'The Charge Point has been deleted',
      type: 'success',
      closeable: true,
    },
    deleteChargePointError: {
      message: 'The Charge Point could not be deleted',
      type: 'error',
      closeable: true,
    },
    editChargePointPermissionsSuccess: {
      message: 'Ther Permissions for the Backend Provider have been edited.',
      type: 'success',
      closeable: true,
    },
    editChargePointPermissionsError: {
      message: 'Ther Permissions for the Backend Provider cannot be edited.',
      type: 'success',
      closeable: true,
    },
  }

  urlMap: Record<string, string> = {
    localhost: 'https://env.kaa.proficloud-development.io/ocpp-proxy/api',
    'app.proficloud-dev.io': 'https://env.kaa.proficloud-development.io/ocpp-proxy/api',
    'app.proficloud-staging.io': 'https://env.kaa.proficloud-staging.io/ocpp-proxy/api',
    'app.proficloud.io': 'https://env.kaa.proficloud-production.io/ocpp-proxy/api',
  }

  showAddChargePointOverlay$ = new Subject<boolean>()

  showAddBackendProviderOverlay$ = new Subject<boolean>()

  showEditChargePointOverlay$ = new Subject<ChargePoint | false>()

  showEditBackendProviderOverlay$ = new Subject<BackendProvider | false>()

  showAssignBackendProviderOverlay$ = new Subject<ChargePoint | false>()

  showAssignChargePointBackendProviderPermissionOverlay$ = new Subject<{ chargePoint: ChargePoint; backendProvider: BackendProvider } | false>()

  showChargePointBackendProviderEditOverlay$ = new Subject<{ chargePoint: ChargePoint; backendProvider: ChargePointBackendProvider } | false>()

  currentEventSource: EventSourcePolyfill

  backend_url: string

  constructor(
    public http: HttpClient,
    public proficloud: ProficloudService,
    private statusOverlay: PcStatusOverlayService
  ) {
    this.backend_url = this.urlMap[window.location.hostname]
    if (this.backend_url === undefined) {
      this.backend_url = this.urlMap['app.proficloud-dev.io']
    }
    this.ocppProxyEndpoint = this.ocppProxyEndpointMap[window.location.hostname]
    if (this.ocppProxyEndpoint === undefined) {
      this.ocppProxyEndpoint = this.ocppProxyEndpointMap['app.proficloud-dev.io']
    }

    this.fetchBackendProvider()
    this.fetchChargePoints()
  }

  fetchBackendProvider() {
    let url = this.backend_url + '/backend-providers'
    this.http.get<BackendProviderResponse>(url).subscribe((data) => {
      this.backend_providers = data.data
    })
  }

  fetchChargePoints() {
    let url = this.backend_url + '/charging-stations'
    this.http.get<ChargePointResponse>(url).subscribe((data) => {
      this.charge_points = data.data
      console.log(this.charge_points)
    })
  }

  fetchChargePointStatus(charge_point: ChargePoint) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId + '/status'
    this.http.get<ChargePointStatusResponse>(url).subscribe((data) => {
      charge_point.status = data.data
    })
  }

  fetchChargePointMeterValue(charge_point: ChargePoint) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId + '/meter-values'
    this.http.get<ChargePointMeterValuesResponse>(url).subscribe((data) => {
      charge_point.meter_values = data.data
    })
  }

  addBackendProvider(backendProviderName: string, backendProviderUrl: string) {
    let url = this.backend_url + '/backend-providers'
    let payload = {
      backendProviderName: backendProviderName,
      backendProviderUrl: backendProviderUrl,
    }
    this.http.post<ChargePoint[]>(url, payload).subscribe({
      next: () => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.addBackendProviderSuccess)
        this.fetchBackendProvider()
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      error: () => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.addBackendProviderError)
      },
    })
  }

  editBackendProvider(backendProviderName: string, backendProviderUrl: string, backendProviderId: string) {
    let url = this.backend_url + '/backend-providers/' + backendProviderId
    let payload = {
      backendProviderName: backendProviderName,
      backendProviderUrl: backendProviderUrl,
    }
    this.http.put<ChargePoint[]>(url, payload).subscribe(
      (response) => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.editBackendProviderSuccess)
        this.fetchBackendProvider()
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      (error) => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.editBackendProviderError)
      }
    )
  }

  deleteBackendProvider(backendProvider: BackendProvider) {
    let url = this.backend_url + '/backend-providers/' + backendProvider.backendProviderId
    this.http.delete<ChargePoint[]>(url).subscribe({
      next: () => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.deleteBackendProviderSuccess)
        this.fetchBackendProvider()
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      error: () => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.deleteBackendProviderError)
      },
    })
  }

  setLimit(charge_point: ChargePoint, limit: number) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId + '/limits'
    let payload = {
      charginglimit: limit,
    }
    this.http.put(url, payload).subscribe({
      next: () => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.addBackendProviderSuccess)
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      error: () => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.addBackendProviderSuccess)
        //this.statusOverlay.showStatus(this.statusOverlays.addBackendProviderError)
      },
    })
  }

  addChargePoint(
    deviceName: string,
    connectorCount: number,
    backend_provider_id?: string,
    backend_provider_username?: string,
    backend_provider_password?: string
  ) {
    let url = this.backend_url + '/charging-stations'
    let payload = {
      deviceName: deviceName,
      connectorCount: connectorCount,
      backendProviders: [
        {
          backendProviderId: backend_provider_id,
          backendProviderUsername: backend_provider_username,
          backendProviderPassword: backend_provider_password,
        },
      ],
      chargingStationType: 'proxyChargepoint',
    }

    const newHeader: any = new HttpHeaders({
      Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
      'Content-Type': 'application/json',
      'X-Security-Assume': this.proficloud.currentOrganisation.organizationId,
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'X-Security-Assume',
    })
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.saveLogBusy)

    this.http.post<ChargePoint[]>(url, payload, { headers: newHeader }).subscribe({
      next: () => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.addChargePointSuccess)
        this.fetchChargePoints()
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      error: () => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.addChargePointError)
      },
    })
  }

  deleteChargePoint(charge_point: ChargePoint) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId
    this.http.delete<ChargePoint[]>(url).subscribe({
      next: () => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.deleteChargePointSuccess)
        this.fetchChargePoints()
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      error: () => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.deleteChargePointError)
      },
    })
  }

  editChargePoint(charge_point: ChargePoint, backends: boolean) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId
    let payload = {}
    if (backends) {
      payload = {
        backendProviders: charge_point.backendProviders,
        connectorCount: charge_point.connectorCount,
      }
    } else {
      payload = {
        connectorCount: charge_point.connectorCount,
      }
    }

    const newHeader: any = new HttpHeaders({
      Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
      'Content-Type': 'application/json',
      'X-Security-Assume': this.proficloud.currentOrganisation.organizationId,
      Accept: 'application/json',
      'Access-Control-Allow-Headers': 'X-Security-Assume',
    })
    this.statusOverlay.showStatus(this.proficloud.statusOverlays.saveLogBusy)

    this.http.put<ChargePoint[]>(url, payload, { headers: newHeader }).subscribe(
      (response) => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.editChargePointSuccess)
        this.fetchChargePoints()
        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      (error) => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.editChargePointError)
        this.fetchChargePoints()
      }
    )
  }

  addChargePointBackendProviderToList(
    charge_point: ChargePoint,
    priority: number,
    backend_provider_id: string,
    backend_provider_username?: string,
    backend_provider_password?: string
  ) {
    let new_backend_provider: ChargePointBackendProvider = {
      backendProviderId: backend_provider_id,
      backendProviderUsername: backend_provider_username,
      backendProviderPassword: backend_provider_password,
      priority: priority,
    }
    charge_point.backendProviders.push(new_backend_provider)

    this.editChargePoint(charge_point, true)
  }

  deleteChargePointBackendProviderFromList(chargePoint: ChargePoint, backendProviderId: string) {
    chargePoint.backendProviders.forEach((element, index) => {
      if (element.backendProviderId == backendProviderId) chargePoint.backendProviders.splice(index, 1)
    })
    this.editChargePoint(chargePoint, true)
  }

  public initiateSseEventListener(charge_point: ChargePoint) {
    charge_point.logs = []

    const url = this.backend_url + '/sse/' + charge_point.endpointId
    this.currentEventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
      },
    })

    const listener = function (event: any) {
      if (charge_point && event) {
        charge_point.logs?.push(JSON.parse(event.data) as ChargePointLogMessage)
      }
    }

    //this.currentEventSource.addEventListener("open", listener);
    this.currentEventSource.addEventListener('message', listener)
    //this.currentEventSource.addEventListener("error", listener);
    //this.currentEventSource.addEventListener("notice", listener);
    //this.currentEventSource.addEventListener("update", listener);
  }

  getChargePointPermissions(charge_point: ChargePoint) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId + '/permissions'
    this.http.get<ChargePointPermissionsResponse>(url).subscribe(
      (response) => {
        charge_point.permissions = response.data
      },
      (error) => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.deleteChargePointError)
      }
    )
  }

  addChargePointPermission(
    charge_point: ChargePoint,
    backendProvider: BackendProvider,
    permission: 'RemoteStartTransaction' | 'RemoteStopTransaction' | 'RemoteUnlockConnector' | 'Reset' | 'ClearCache' | 'GetConfiguration'
  ) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId + '/permissions'
    let payload = {
      backendProviderId: backendProvider.backendProviderId,
      permission: permission,
    }

    this.http.post<ChargePointPermissionsResponse>(url, payload).subscribe(
      (response) => {
        // success overlay
        this.statusOverlay.showStatus(this.statusOverlays.editChargePointPermissionsSuccess)
        this.getChargePointPermissions(charge_point)

        // clear success overlay after timeout
        setTimeout(() => {
          this.statusOverlay.resetStatus()
        }, 2000)
      },
      (error) => {
        // Show error message
        this.statusOverlay.showStatus(this.statusOverlays.editChargePointPermissionsError)
      }
    )
  }

  deleteChargePointPermission(
    charge_point: ChargePoint,
    backendProvider: BackendProvider,
    permission: 'RemoteStartTransaction' | 'RemoteStopTransaction' | 'RemoteUnlockConnector' | 'Reset' | 'ClearCache' | 'GetConfiguration'
  ) {
    let url = this.backend_url + '/charging-stations/' + charge_point.endpointId + '/permissions'
    let payload = {
      backendProviderId: backendProvider.backendProviderId,
      permission: permission,
    }

    this.http
      .delete<ChargePointPermissionsResponse>(url, {
        body: payload,
      })
      .subscribe(
        (response) => {
          // success overlay
          this.statusOverlay.showStatus(this.statusOverlays.editChargePointPermissionsSuccess)
          this.getChargePointPermissions(charge_point)

          // clear success overlay after timeout
          setTimeout(() => {
            this.statusOverlay.resetStatus()
          }, 2000)
        },
        (error) => {
          // Show error message
          this.statusOverlay.showStatus(this.statusOverlays.editChargePointPermissionsError)
        }
      )
  }

  //#################################################
  //################## V Helper V ###################

  getBackendProvider(backend_provider_id: string) {
    return this.backend_providers.find((backend_provider) => backend_provider.backendProviderId == backend_provider_id)
  }

  isPermissionSet(
    chargePoint: ChargePoint,
    backendProvider: BackendProvider,
    permission: 'RemoteStartTransaction' | 'RemoteStopTransaction' | 'RemoteUnlockConnector' | 'Reset' | 'ClearCache' | 'GetConfiguration'
  ): boolean {
    if (!chargePoint.permissions) {
      this.getChargePointPermissions(chargePoint)
    }

    const perm = chargePoint.permissions?.find(
      (backendProviderPermissions) => backendProviderPermissions.backendProviderId === backendProvider.backendProviderId
    )

    if (perm) {
      return perm.permissions.find((permissionIter) => permissionIter === permission) != undefined
    } else {
      return false
    }
  }

  togglePermission(
    chargePoint: ChargePoint,
    backendProvider: BackendProvider,
    permission: 'RemoteStartTransaction' | 'RemoteStopTransaction' | 'RemoteUnlockConnector' | 'Reset' | 'ClearCache' | 'GetConfiguration'
  ) {
    if (this.isPermissionSet(chargePoint, backendProvider, permission)) {
      this.deleteChargePointPermission(chargePoint, backendProvider, permission)
    } else {
      this.addChargePointPermission(chargePoint, backendProvider, permission)
    }
  }

  moveBackendProviderPrioUp(chargePoint: ChargePoint, backendProviderId: string) {
    let backendProvider = chargePoint.backendProviders.find((bP) => bP.backendProviderId == backendProviderId)

    if (backendProvider) {
      const oldPrio = backendProvider.priority
      backendProvider.priority = oldPrio - 1

      const backendProvider2 = chargePoint.backendProviders.find((bP) => bP.priority == oldPrio - 1)

      if (backendProvider2) {
        backendProvider2.priority = oldPrio
      } else {
        console.error('No backend provider found.')
      }

      this.editChargePoint(chargePoint, true)
    } else {
      console.error('No backend provider found.')
    }
  }

  moveBackendProviderPrioDown(chargePoint: ChargePoint, backendProviderId: string) {
    let backendProvider = chargePoint.backendProviders.find((bP) => bP.backendProviderId == backendProviderId)

    if (backendProvider) {
      const oldPrio = backendProvider.priority
      backendProvider.priority = oldPrio + 1

      const backendProvider2 = chargePoint.backendProviders.find((bP) => bP.priority == oldPrio + 1)

      if (backendProvider2) {
        backendProvider2.priority = oldPrio
      } else {
        console.error('No backend provider found.')
      }

      this.editChargePoint(chargePoint, true)
    } else {
      console.error('No backend provider found.')
    }
  }
}
